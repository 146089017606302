<template>
  <div class="reports-period-group">
    <h3>Период - группа</h3>
    <PeriodGroup />
  </div>
</template>

<script>
import PeriodGroup from "@/components/reports/PeriodGroup"

export default {
  name: "ReportsPeriodGroup",
  components: {
    PeriodGroup,
  },
}
</script>

<style scoped></style>
