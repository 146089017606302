<template>
  <div class="diagrams">
    <div v-for="diagram in list" :key="diagram.link" class="diagram">
      <img :src="diagram.link" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Diagrams",
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.diagrams {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  .diagram {
    margin: 20px 0;
  }
}
</style>
