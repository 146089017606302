<template>
  <div class="period-sum">
    <h3>Линии</h3>

    <div class="main-table table">
      <a-table
        bordered
        :columns="mainTableColumns"
        :data-source="mainTableData"
        :scroll="{ x: 2500 }"
        :pagination="{ defaultPageSize: 10, hideOnSinglePage: true }"
      ></a-table>
    </div>

    <div class="other-tables">
      <div class="hideButtonHolder">
        <a-button
          type="primary"
          class="hideButton"
          @click="toggleOtherTablesVisibility"
        >
          {{ isShowOtherTables ? "Скрыть" : "Показать" }}
        </a-button>
      </div>

      <template v-if="isShowOtherTables">
        <template v-for="(table, index) in otherTables">
          <a-table
            bordered
            class="other-table table"
            :columns="table.columns"
            :data-source="table.data"
            :key="index"
            :scroll="{ x: 2500 }"
            :pagination="{ defaultPageSize: 10, hideOnSinglePage: true }"
          ></a-table>
        </template>
      </template>
    </div>

    <Diagrams :list="diagramsList" />
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex"

import Diagrams from "@/components/diagrams/Diagrams"

export default {
  name: "PeriodGroupLine",
  components: {
    Diagrams,
  },
  data() {
    return {
      diagramsList: [
        {
          link: require("../../../assets/reports/dynamic/line/diagram1.png"),
        },
        {
          link: require("../../../assets/reports/dynamic/line/diagram2.png"),
        },
        {
          link: require("../../../assets/reports/dynamic/line/diagram3.png"),
        },
      ],
    }
  },
  mounted() {
    this.fetchMainTableData()
    this.fetchOtherTablesData()
  },
  computed: mapGetters({
    mainTableColumns: "reportPeriodGroup/lineMainColumns",
    mainTableData: "reportPeriodGroup/lineMainData",
    otherTables: "reportPeriodGroup/lineOtherTables",
    isShowOtherTables: "reportPeriodGroup/lineIsShowOtherTables",
  }),
  methods: {
    ...mapMutations({
      toggleOtherTablesVisibility:
        "reportPeriodGroup/TOGGLE_LINE_OTHER_TABLES_VISIBILITY",
    }),
    ...mapActions({
      fetchMainTableData: "reportPeriodGroup/fetchLineMainData",
      fetchOtherTablesData: "reportPeriodGroup/fetchLineOtherData",
    }),
  },
}
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.hideButtonHolder {
  margin-top: 20px;
}

.hideButton {
  width: 100%;
}

.table {
  margin-bottom: 30px;
}
</style>
