<template>
  <div class="period-group">
    <Filters />
    <PeriodGroupLine class="element" v-if="isLineShow" />
    <PeriodGroupSum class="element" v-if="isSumShow" />
  </div>
</template>

<script>
import { mapGetters } from "vuex"

import Filters from "@/components/reports/period-group/Filters"
import PeriodGroupLine from "@/components/reports/period-group/PeriodGroupLine"
import PeriodGroupSum from "@/components/reports/period-group/PeriodGroupSum"

export default {
  name: "PeriodGroup",
  components: {
    Filters,
    PeriodGroupLine,
    PeriodGroupSum,
  },
  computed: mapGetters({
    isLineShow: "reportPeriodGroup/isLineShow",
    isSumShow: "reportPeriodGroup/isSumShow",
  }),
}
</script>

<style lang="scss" scoped>
.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-bottom: 10px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#advanced-search .ant-form {
  max-width: none;
}
#advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  padding: 20px 40px;
}
.search-result-text {
  margin: 20px 0 10px 0;
}
.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.leftButtons {
  margin-right: 20px;
}
.rightButtons {
  margin-left: 20px;
}
.filter-fields {
  display: flex;
  width: 50%;
  flex-direction: column;
}
.filter-form-items {
  display: block;
  margin-right: 20px;
  margin-bottom: 10px !important;
}
.selects {
  width: 200px;
}
.filter-and-show {
  display: flex;
  flex-direction: row;
}
.dealer-select {
  margin-left: 40px;
}
.reg-select {
  margin-left: 36px;
}
.brand-select {
  margin-left: 40px;
}
.manager-select {
  margin-left: 12px;
}
.period-select {
  margin-left: 31px;
}
.lines-select {
  margin-left: 20px;
}
.amounts-select {
  margin-left: 14px;
}
.hideButtonHolder {
  margin-top: 20px;
}
.hideButton {
  width: 100%;
}
</style>
